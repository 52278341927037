<template>
  <div>
    <b-tabs>
      <b-tab title="Cookies">
        <template #title>
          <span class="tab-text">Cookies</span>
        </template>
        <div class="cookie-doc">
          <p>
            We, <strong>To the Point Events</strong>, with registered office at
            <strong>Kempische Steenweg 303 b21 3500 Hasselt</strong>, with
            company number <strong>0477.191.005</strong>, mainly make use of
            cookies to enhance the browsing experience of visitors to our
            website <router-link to="/">{{ url }}</router-link
            >.
          </p>
          <p>
            Any data obtained through our cookies is used exclusively to
            understand your use of our website, with a view to improving the
            performance, functionalities, and usability of our website. By using
            the information and services on
            <router-link to="/">{{ url }}</router-link
            >, you confirm that you have read and understood our cookie policy
            and the conditions included therein. Generally, a cookie is a small
            text and numeric file that we send and store in your web browser, on
            your mobile device and/or on your computer’s hard disk. This allows
            us to remember your preferences when using our website (e.g. your
            language selection, preferences with regard to sorting, etc.) unless
            you have adjusted your browser settings in order to reject cookies.
            Cookies allow the website’s functioning but may also collect
            information for commercial purposes.
          </p>
          <h1>Type of cookies</h1>
          <p>
            Cookies can be categorised according to their origin, function, and
            lifespan:
          </p>
          <p>
            Categorised according to their origin, one can distinguish between
            cookies managed by our company on the one hand, and cookies managed
            by other companies which administrate that management on our behalf,
            on the other hand. We do not use third party cookies which allow
            those third parties to use the obtained date for their own purpose.
          </p>
          <p>Categorised according to their function, there are</p>
          <ul>
            <li>
              <strong>Essential or strictly necessary cookies</strong>, which
              are (as their name indicates) necessary to use a website, to
              provide certain functions requested by you and to ensure a safe
              identity verification;
            </li>
            <li>
              <strong>Functional cookies</strong>, which are cookies that ensure
              the proper functioning of our website. They help to remember your
              choices and preferences on our website (e.g. cookies for log-in or
              registration, language preferences, products in your shopping
              cart). Functional cookies are logically first-party cookies;
            </li>
            <li>
              <strong>Cookies with statistic/analytic purposes</strong>
              (also called ‘performance cookies’) allow us to count visitors and
              their origins to measure and improve the performance of our
              website. They help us understand which pages are the most and
              least popular and how visitors move throughout the website (Google
              Analytics, Google Tag Manager, Hotjar).
            </li>
            <li>
              <strong>Cookies with social purposes</strong>, which make it
              possible for the user to share the content of the visited website
              directly with others via social media (Facebook, Twitter,
              Linkedin, Whatsapp).
            </li>
            <li>
              <strong>Cookies with targeting/tracking purposes</strong> , which
              allow to follow the surfing behaviour of visitors and build up a
              profile based on your surfing behaviour, as a result of which the
              displayed advertisements are tailored to your interests
              (Facebook). It is mandatory to request and obtain permission for
              such cookies.
            </li>
            <li>
              <strong>Cookies with commercial purposes</strong> keep track of
              how many and which advertisements were shown to a user.
            </li>
          </ul>
          Cookies can also be categorised according to their lifespan. Here, we
          distinguish between:
          <ul>
            <li>
              <strong>Permanent cookies</strong>: These cookies remain on the
              user’s device for the duration determined in the cookie. They are
              activated each time the user visits the website that has placed
              the cookie (e.g. cookies placed by social media such as Facebook,
              Google Analytics, etc.). Most non-functional cookies are permanent
              cookies.
            </li>
            <li>
              <strong>Session cookies</strong>: These cookies allow us to
              simplify and link a user’s actions during a browser session. A
              browser session starts when a user opens the browser screen and
              ends when they close the browser screen. Session cookies are
              placed temporarily. As soon as you close the browser, all session
              cookies are deleted. Most functional cookies are session cookies.
            </li>
          </ul>
          <br />
          <h1>Used cookies</h1>
          <p>
            Find below the management, function, and lifespan for each cookie.
            If the cookies we use collect and process personal data, this is
            done in conformity with our
            <a
              href="https://www.website.be/privacy"
              target="_blank"
              rel="noopener noreferrer"
              >privacy policy</a
            >. Here, you will also find your rights with regard to personal
            data. personnelles.
          </p>

          <!-- <h1>COMPLIANCE</h1>
    <p>We gebruiken Complianz voor het registreren van toestemming voor cookies. <a href="https://cookiedatabase.org/service/complianz">Lees meer</a></p>
    <p><b>Type: functioneel</b></p>
    <b-table striped hover :items="compliance_items"></b-table>
    <p>Voor meer informatie, lees de <a href="https://complianz.io/privacy-statement/">Complianz Privacy Policy.</a></p> -->

          <!-- <h1>WPML</h1>
    <p>We gebruiken WPML voor taalbeheer. <a href="https://cookiedatabase.org/service/wpml">Lees meer</a></p>
    <p><b>Type: functioneel</b></p>
    <b-table striped hover :items="wpml_items"></b-table>
    <p>Deze gegevens worden niet gedeeld met derden.</p> -->

          <!-- <h1>GOOGLE ANALYTICS</h1>
    <p>We gebruiken Google Analytics voor websitestatistieken. <a href="https://cookiedatabase.org/service/google-analytics">Lees meer</a></p>
    <p><b>Type: statistieken (anoniem zonder ip)</b></p>
    <b-table striped hover :items="googleAnal_items"></b-table>
    <p>Voor meer informatie, lees de <a href="https://policies.google.com/privacy">Google Analytics Privacy Policy.</a></p> -->

          <h1>GOOGLE FONTS</h1>
          <p>
            We use Google Fonts for displaying web fonts.
            <a href="https://cookiedatabase.org/service/google-fonts"
              >Read more</a
            >
          </p>
          <p><b>Type: third-party (marketing/tracking)</b></p>
          <b-table striped hover :items="googleFont_items"></b-table>
          <p>
            For more information, read the
            <a href="https://policies.google.com/privacy"
              >Google Fonts Privacy Policy.</a
            >
          </p>

          <h1>VIMEO</h1>
          <p>
            We use Vimeo for showing videos.
            <a href="https://cookiedatabase.org/service/vimeo">Read more</a>
          </p>
          <p><b>Type: statistics</b></p>
          <b-table striped hover :items="vimeo_items"></b-table>
          <p>
            For more information, read the
            <a href="https://vimeo.com/privacy">Vimeo Privacy Policy.</a>
          </p>

          <!-- <h1>RUMBLETALK</h1>
    <p>We gebruiken RumbleTalk om geregistreerde gebruikers in groep te laten chatten, 1-op-1 of 1-op-1 video- en audiogesprekken te voeren. <a href="https://cookiedatabase.org/service/vimeo">Lees meer</a></p>
    <p><b>Type: third-party (marketing/tracking)</b></p>
    <b-table striped hover :items="rumble_items"></b-table>
    <p>Voor meer informatie, lees de <a href="https://rumbletalk.com/about_us/privacy_policy/">RumbleTalk Privacy Policy.</a></p> -->

          <h1>SLIDO</h1>
          <p>
            We use Slido to give registered users the option to ask questions
            while following a live stream or participate in polls.
          </p>
          <p><b>Type: third-party (marketing/tracking)</b></p>
          <b-table striped hover :items="slido_items"></b-table>
          <p>
            For more information, read the
            <a href="https://www.sli.do/terms#privacy-policy"
              >Slido Privacy Policy.</a
            >
          </p>

          <h1>ZOOM</h1>
          <p>
            We use Zoom to enable registered users to connect with each other
            through video group conversations.
          </p>
          <p><b>Type: Functional</b></p>
          <b-table striped hover :items="vimeo_items"></b-table>
          <p>
            For more information, read the
            <a href="https://zoom.us/privacy-and-legal">Zoom Privacy Policy.</a>
          </p>

          <h1>Accept cookies</h1>
          <p>
            When you first visit our website, you shall be asked to accept our
            different types of cookies for which your consent is required. You
            can block cookies by activating the browser setting allowing you to
            refuse the placement of new cookies. However, if you choose to use
            your browser settings to block these cookies, you might not be able
            to access (certain parts of) our website. Please read this cookie
            policy carefully for more information. If at any time you wish to
            withdraw your consent, you can always delete your cookies by
            managing your browser settings.
          </p>
          <h1>Remove or disable cookies</h1>
          <p>
            If you do not want websites to place cookies on your computer or you
            want to remove your cookies, you can change your cookie settings in
            your web browser. Usually, these settings can be found in the
            ‘Options’ or ‘Preferences’ menu of your web browser. You can also
            adjust your settings so that your browser blocks all cookies or
            blocks only the cookies from third parties. If you do not wish to
            use cookies, we can no longer guarantee the proper functioning of
            our website. It may be that some functions of the website will be
            lost or you may not be able to see certain pages at all. In
            addition, the refusal of cookies does not mean you shall no longer
            see advertisements at all. The advertisements shall no longer be
            tailored to your interests and can be repeated more often. The way
            you adjust your settings varies from browser to browser. To
            understand these settings more clearly, please consult the ‘Help’
            function in your web browser for more details. You can find
            additional and useful information about cookies at:
            <a href="http://www.allaboutcookies.org"
              >http://www.allaboutcookies.org</a
            >.
          </p>
          <h1>Do you have any further questions?</h1>
          <p>
            For any further questions regarding this cookie policy, please feel
            free to contact us via
            <a href="info@tothepointevents.be">info@tothepointevents.be</a>.
          </p>
          <h1>Amendments</h1>
          <p>
            We may have to modify, complete or supplement this cookie policy
            from time to time without prior notification. Therefore, we invite
            you to consult our website for the latest version of this policy. Of
            course, we shall always ask your permission for the use of certain
            cookies.
          </p>
        </div>
      </b-tab>
      <b-tab title="Privacy">
        <template #title>
          <span class="tab-text">Privacy</span>
        </template>
        <div class="privacy-doc">
          <h1>Our approach to respecting your privacy</h1>

          <p>
            Thank you for trusting us with your personal data. We,
            <strong>To The Point Events</strong>, with registered office at
            Walter Thijsstraat 2, 3500 Hasselt, with company number
            0886.029.276, are responsible for processing your personal data in
            relation to your intended participation at our
            <strong>{{ client }}</strong
            >(hereinafter referred to as ‘the Event’). In that respect, we act
            as controller of your personal data that determines the purposes and
            means of the processing of personal data in the context of
            Regulation (EU) 2016/679 (“GDPR”).
          </p>

          <p>
            We greatly respect your privacy and we are committed to protecting
            it through compliance with this privacy statement. We commit
            ourselves to be transparent with you by providing clear information
            about what personal data we collect; what we do with it and why; who
            we disclose it to; how we protect personal data and what choices you
            have regarding the use of your personal data by us and third
            parties.
          </p>

          <p>
            We reserve the right to adjust this privacy statement at any time if
            new developments give cause to do so. The most current privacy
            policy can always be found on the website
            <router-link to="/">{{ url }}</router-link
            >.
          </p>

          <h1>What personal data do we process and why?</h1>

          <p>
            ‘Processing’ of personal data means any operation or set of
            operations which is performed on personal data or on sets of
            personal data, whether or not by automated means, such as
            collection, recording, organisation, structuring, storage,
            adaptation or alteration, retrieval, consultation, use, disclosure
            by transmission, dissemination or otherwise making available,
            alignment or combination, restriction, erasure or destruction.
          </p>

          <p>
            This section tells you what personal data we process, where we got
            it from and why.
          </p>

          <p>
            If you wish to participate in the Event – which will be held
            digitally on a platform that is accessible by means of the domain
            <router-link to="/">{{ url }}</router-link> (hereinafter referred to
            as ‘the Platform’) – this participation requires a prior
            registration on <router-link to="/">{{ url }}</router-link
            >. This prior registration in its turn will require some personal
            data to be shared with us. These personal data may imply:
          </p>

          <ul>
            <li>
              Identity Data which includes your first name, maiden name, last
              name, username or similar identifier, profile picture, title,
              professional position, and gender. This may also include other
              audio-visual content which you appear in as you contribute to or
              participate in the Event.
            </li>
            <li>
              Contact Data which includes your email address, company address,
              country.
            </li>
            <li>
              Technical Data which includes your login data, browser type and
              version, hardware information, time zone setting and location,
              browser plug-in types and versions, operating system and website,
              and other technology on the devices you use to access The
              Platform.
            </li>
            <li>
              Profile Data which includes your username and password,
              preferences, feedback and survey responses.
            </li>
            <li>
              Usage Data which includes information about how you use The
              Platform services.
            </li>
            <li>
              We also collect, use and share Aggregated Data such as statistical
              or demographic data for any purpose. Aggregated Data could be
              derived from your personal data but is not considered personal
              data as this data will not directly or indirectly reveal your
              identity.
            </li>
          </ul>
          <br />
          <p>
            Next to these data that you actively share with us by filling out
            the registration form, we may also process data relating to the IP
            address you use in order to enhance the functioning of the services
            related to the Event.
          </p>
          <p>
            When the registration is completed, an email will be generated to
            the registered participant with a confirmation of his participation
            and the possibility to request his password again or to change it.
            The access to the Platform will only be unlocked when the event
            takes place.
          </p>

          <h1>
            What are the purposes of the processing for which the personal data
            are intended and what is the legal basis for such processing?
          </h1>

          <p>
            We collect personal data first of all on the basis of your informed
            consent.
          </p>

          <p>
            We also, in as far as necessary, collect personal data to allow the
            user to participate in activities that take place through the
            Platform and hence in order to execute the agreement that we
            conclude with you regarding the participation of the Event.
          </p>

          <p>
            At last we collect your personal data for the purposes of the
            legitimate interests pursued by us as controller, namely in order to
            get your feedback on our services and proposals.
          </p>

          <h1>The protection of your personal data</h1>

          <p>
            We do not sell or trade your personal data to other third parties.
            We only disclose personal data to authorised service providers (see
            below).
          </p>

          <p>
            The number of persons who have access to the personal data of the
            user is limited. Access is only granted to the extent that the
            information concerned is necessary for the proper performance of
            their task. In any case, these authorized persons are bound by a
            confidentiality obligation.
          </p>

          <p>
            We have implemented reasonable measures to protect your personal
            data against destruction, loss, alteration, unauthorized disclosure
            or access in accordance with the provisions of Regulation (EU)
            2016/679 (“GDPR”).
          </p>

          <h1>The sharing of your data</h1>

          <p>
            We hereby inform you that the Event will be managed by
            <strong>Medialife BV</strong>, with registered office in Walter
            Thijsstraat 2, 3500 Hasselt, that will also be in charge – following
            a written authorisation by us – of the processing and management of
            the personal data that is being collected to this end and in this
            regard.
          </p>

          <p>
            We have also authorised Medialife BV to make use of dedicated
            sub-processors that provide sufficient guarantees to implement
            appropriate technical and organisational measures in such a manner
            that the processing will meet the requirements of the GDPR
            Regulation and ensure the protection of the rights of the data
            subject.
          </p>

          <h1>How long do we process your personal data?</h1>
          <p>
            We process the personal data of the user for the duration of the
            Event. After the Event, your personal data will be immediately and
            permanently deleted.
          </p>
          <h5>What are your privacy rights?</h5>
          <p>
            This section explains the rights you have regarding your personal
            data. If you would like to exercise any of your rights, or receive
            more information about them, please contact us via the contact
            details at the end of this Privacy Statement and we will help you
            out.
          </p>
          <h5>Right of access</h5>
          <p>
            You have the right to obtain confirmation as to whether or not we
            process your personal data, and, if we do, request access to your
            personal data as well as information in relation to our processing.
            If you have any questions or would like to learn more about what
            personal data we process, you are always welcome to contact us and
            we will provide you with further information.
          </p>
          <h5>Right to rectification</h5>
          <p>
            If you believe we store incorrect personal data, such as misspelt or
            previous name or address, you can ask us to correct this.
          </p>
          <h5>Right to erasure / right to be forgotten</h5>
          <p>
            You have the right to request that we permanently erase your
            personal data from our records.
          </p>
          <h5>Right to withdraw consent</h5>
          <p>
            In case the processing of your personal data is based on your
            consent, you have the right to withdraw your consent at any time,
            without affecting the lawfulness of processing based on consent
            before this withdrawal. Please note that all aspects of an initiated
            marketing campaign cannot be stopped once initiated upon a
            withdrawal of consent.
          </p>
          <h5>Right to lodge a complaint with a supervisory authority</h5>
          <p>
            If you aren’t satisfied with the way we treat your personal data,
            you have the right to lodge a complaint against us to the relevant
            supervisory authority; for Belgium: Data Protection Authority or
            “DPA”. You can find the contact details below.
          </p>
          <h1>Who to contact?</h1>
          <p>
            If you have questions about our privacy statement or practices,
            please feel free to get in touch. You can contact
            <strong>To The Point Events</strong> as the general manager of the
            Event at:
            <a href="info@tothepointevents.be">info@tothepointevents.be</a>.
          </p>

          <p>
            If you have a complaint about how your personal data are being
            processed, you can contact the Data Protection Authority at:
          </p>
          <p>Drukpersstraat 35, 1000 Brussels</p>
          <p>
            E-mail : <a href="mailto:contact@apd-gba.be">contact@apd-gba.be</a>
          </p>
          <p>Telephone number: +32 (0)2 274 48 00</p>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: window.location.hostname,
      compliance_items: [
        {
          Name: "complianz_policy_id",
          Retention: "1 jaar",
          Function: "Registreert de geaccepteerde cookiebeleid-ID.",
        },
        {
          Name: "complianz_content_status",
          Retention: "1 jaar",
          Function: "Slaat cookievoorkeur op.",
        },
        {
          Name: "complianz_cmplz_marketing",
          Retention: "1 jaar",
          Function: "Slaat cookievoorkeur op.",
        },
      ],
      wpml_items: [
        {
          Name: "wp-wpml_current_language",
          Retention: "1 dag",
          Function: "Slaat taalvoorkeuren op.",
        },
      ],
      googleAnal_items: [
        {
          Name: "_ga",
          Retention: "2 jaar",
          Function:
            "Volgt en verzamelt analyse van paginaweergaven voor websitestatistieken.",
        },
        {
          Name: "_gat",
          Retention: "1 minuut",
          Function: "Filtert verzoeken van bots.",
        },
        {
          Name: "_gid",
          Retention: "1 dag",
          Function:
            "Volgt en verzamelt analyse van paginaweergaven voor websitestatistieken.",
        },
      ],
      googleFont_items: [
        {
          Name: "Google Fonts API",
          Retention: "none",
          Function: "Requests the user's IP address.",
        },
      ],
      vimeo_items: [
        {
          Name: "__utmt_player",
          Retention: "10 minutes",
          Function: "Tracks target audience reach.",
        },
        {
          Name: "vuid",
          Retention: "2 years",
          Function: "Stores the user's usage history.",
        },
      ],
      rumble_items: [
        {
          Name: "AWSALBCORS",
          Retention: "1 week",
          Function:
            "Amazon’s cookie voor CORS-toegang. Deze is nodig om CORS toegang te krijgen in Amazon.",
        },
        {
          Name: "s",
          Retention: "sessie",
          Function:
            "Een sessiecookie die wordt gebruikt om de gebruiker te verbinden met zijn huidige sessie. Het wordt verwijderd wanneer het tabblad wordt gesloten en aangemaakt wanneer het tabblad wordt geopend.",
        },
        {
          Name: "r",
          Retention: "2 weken",
          Function:
            "Deze cookie wordt geplaatst wanneer de gebruiker inlogt en wordt gebruikt om de gebruiker ingelogd",
        },
      ],
      slido_items: [
        {
          Name: "__exponea_(*), xnpe_(*), __EX(*)",
          Retention: "3 years",
          Function:
            "These cookies are used to collect information about how visitors use features and our website. We use the information to compile reports and improve our website. The cookies collect information in a way that does not directly identify anyone until they create a Slido account.",
        },
        {
          Name: "_fbp fr, act, oo, presence, spin, wd, xs, sb, sfau, wd",
          Retention: "3 mois",
          Function:
            "Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.",
        },
        {
          Name: "Slido.Privacy",
          Retention: "10 years",
          Function:
            "Stores the user's cookie consent state for the current domain.",
        },
        {
          Name: "slido_(*), Slido.(*), currentAccountUuid, AWSALB, _passenger_route",
          Retention: "10 years",
          Function:
            "Technical, required for our infrastructure (cookie holds a token to verify the login session etc. ...).",
        },
        {
          Name: "JSESSIONID, TSNGUID, optimizelyEndUserId, _gcl_au, _mkto_trk, s_(*), _persistenceTest",
          Retention: "session",
          Function:
            "We use New Relic service to monitor uptime/downtime status of our websites.",
        },
      ],
      zoom_items: [
        {
          Name: "_zm_lang",
          Retention: "1 year",
          Function: "Saves language preferences.",
        },
      ],
    };
  },
  computed: {
    client() {
      return this.$store.state.Config.all.name;
    },
  },
};
</script>

<style lang="scss" scoped>
html,
.cookie-doc,
.privacy-doc {
  padding: 50px;
  background: #fff;
}
h1,
h4,
h1 {
  margin-top: 15px;
  margin-bottom: 15px;
}
a {
  color: blue;
  :hover {
    color: black;
  }
}
.elipsis-list {
  ul,
  li {
    list-style-type: circle !important;
  }
}
.nav-link:hover {
  span {
    color: darken(#fff, 5%);
  }
}
</style>